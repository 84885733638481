<template>
  <div class="navbar">
    <div class="navbar-item item1">
      <a href="/">
        <img class="navbar-logo" :src="logo" />
      </a>
    </div>
    <div v-if="isCollapse" class="navbar-item item2"></div>
    <div v-else class="navbar-item item2">
      <div id="play">
        <a v-for="item in ads" :href="item.url" :key="item.url" target="_blank">
          <div class="navbar-item-container">
            <img class="navbar-avatar" :src="item.cover" />
          </div>
          <div class="navbar-item-container navbar-username">{{ item.title }}</div>
        </a>
        <a v-if="ads.length > 0" :href="ads[0].url" target="_blank">
          <div class="navbar-item-container">
            <img class="navbar-avatar" :src="ads[0].cover" />
          </div>
          <div class="navbar-item-container navbar-username">{{ ads[0].title }}</div>
        </a>
      </div>
    </div>
    <div v-if="isCollapse" class="navbar-item item3">
      <i class="el-icon-menu" @click.prevent="showMenus"></i>
      <div v-if="menusShow" class="mobile-menus">
        <a
          class
          v-for="link in navLinks"
          :key="link.title"
          :href="link.url"
          target="_blank"
        >{{ link.title }}</a>
        <a
          class
          v-for="lang in Object.keys(langUrls)"
          :key="lang"
          :href="langUrls[lang]"
        >{{ lang }}</a>
      </div>
    </div>
    <div v-else class="navbar-item item3">
          <span style="width:32%" class="lang-change" @mouseover="showLang" @mouseleave="hideLang">
            <button @click="showLang" type="button"></button>
            <ul v-show="langShow">
              <li 
                 v-for="item in langTab"
                 :key="item"
                 @click="changeLang(item)"
                 :class="[seletedLang === item ? 'active' : '']" 
                 >
                {{item}}
                </li>
            </ul>
          </span>
      <template>
        <a
          :class="link.active?'navbar-item-container last-item nav-link  active':'navbar-item-container last-item nav-link'"
          v-for="link in navLinks"
          :key="link.title"
          :href="link.url"
          target="_blank"
        >{{ link.title }}</a>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import request from '../../../api/request'

const accountHost = process.env.VUE_APP_ACCOUNT_HOST

const langMappings = {
    'ko': '한국어',
    'jp': '日本語',
    'tw': '中文（繁體）',
}

const langUrls = {
    '한국어': 'https://www.pangsky.net',
    '日本語': 'https://jp.panggame.com',
    '中文（繁體）': 'https://tw.panggame.com',
}

const locale = process.env.VUE_APP_LOCALE?process.env.VUE_APP_LOCALE:'ko'

export default {
  name: "Navbar",
  data() {
    return {
      menusShow: false,
      logo: require("../../../assets/images/logo.png"),
      ads: [
      ],
      locale: locale,
      location: "KOREA",
      langUrls: langUrls,
      seletedLang: langMappings[locale],
      langTab: Object.values(langMappings),
      langShow: false,
      navLinks: accountHost?[
        {
          title: this.$t("로그인"),
          active: false,
          url: `https://${accountHost}/#/login`,
        },
        {
          title: this.$t("회원가입"),
          active: false,
          url:
            `https://${accountHost}/#/register`,
        },
      ]:[],
    };
  },
  computed: {
    ...mapGetters(["screenWidth"]),
    isCollapse() {
      return this.screenWidth < 768;
    },
  },
  methods: {
      changeLang (item) {
          if (this.seletedLang !== item) {
              this.seletedLang = item;
                window.location.href = langUrls[item]
          }
      },
      hideLang () {
        this.langShow = false
      },
      showLang () {
          this.langShow = true
      },
    showMenus() {
      this.menusShow = !this.menusShow;
      console.log(this.screenWidth);
    },
      getTops () {
          let _this = this
          request.get('/tops').then(function (res) {
            if (res.status == 200) {
                _this.ads = res.data.results
            }
          })
      },
  },
  mounted () {
      this.getTops()
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.el-menu {
  background-color: #f8f9fa;
}

.el-icon-menu {
  float: right;
  line-height: 56px;
  padding: 0px 20px;
}

.navbar {
  max-width: 1277px;
  width: 100%;
  margin: auto;
  padding: 10px 0;
  display:flex;
  justify-content: space-between;
}

.navbar-logo {
  margin-top: 5px;
  margin-bottom: -10px;
}

.navbar-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-bottom: -13px;
}

.item1 {
  margin-left: 12px;
  max-width: 409px;
  width: 32.96vw;
}

.item2 {
  max-width: 421px;
  width: 30vw;
  height: 56px;
  overflow: hidden;
  margin-bottom: -20px;
}

.navbar-username {
  color: #626262;
  margin-left: 20px;
}

.item3 {
  max-width: 435px;
  width: 30vw;
  margin-bottom: -23px;
}

.last-item {
  float: right;
}

.location {
  background-color: #3b3b3a;
  color: #cbcbcb;
  height: 32px;
  width: 120px;
  border-radius: 5px;
  margin-top: 11.5px;
  line-height: 32px;
}

.location-text {
  width: 70px;
  padding-left: 12px;
  font-size: 13px;
}

.location-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-bottom: -4px;
}

.nav-link {
  color: #575757;
  width: 70px;
  font-size: 13px;
  text-decoration: none;
}

.active {
  color: #0d80ff;
}

.navbar-item {
  line-height: 56px;
  display: inline-block;
}
.navbar-item-container {
  display: inline-block;
}
.item2 a {
  display: block;
}

#play {
  animation: carousel 8s ease-in-out infinite;
}

@keyframes carousel {
  0%,
  10% {
    margin-top: 0px;
  }
  25%,
  35% {
    margin-top: -56px;
  }
  50%,
  60% {
    margin-top: -112px;
  }
  75%,
  80% {
    margin-top: -168px;
  }
  80%,
  100% {
    margin-top: -168px;
  }
}

.mobile-menus {
  position: absolute;
  width: 30vw;
  background-color: #fff;
  top: 56px;
  right: 10px;
  z-index: 999;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.mobile-menus a {
  display: block;
  color: #575757;
  font-size: 13px;
  text-decoration: none;
  margin-left: 20px;
}

.lang-change {
    position: relative;
    float: right;
}

.lang-change button{
  background: url("../../../assets/images/m_icn_lang.svg");
  background-repeat: no-repeat !important;  
  background-size: 100% 100% !important;  
  border: none;
  width: 25px;
  height: 25px;
  outline: none;
  margin-top: 15px;
  cursor: pointer;
}

.lang-change ul {
    position: absolute;
    top: 40px;
    width: 100px;
    list-style: none;
    padding-inline-start: 20px;
    padding-bottom: 10px;
    background-color: #F8F9FA;
    z-index: 999999999;
    margin-left: -20px;
}

.lang-change ul li{
  height: 30px;
  line-height: 30px;
  font-weight: bolder;
  position: relative;
  cursor: pointer;
}

</style>