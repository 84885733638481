export const m = {
  신규게임: "新作ゲーム",
  "진행중인 이벤트": "イベント開催中",
  "이달의 쿠폰": "今月のクーポン",
  쿠폰: "クーポン",
  복사: "コピー",
  전체게임: "ゲーム全体",
  모바일게임: "モバイルゲーム",
  PC게임: "PCゲーム",
  회사소개: "会社紹介",
  이용약관: "利用規約",
  "개인정보 처리방침": "個人情報処理方針",
  고객센터: "カスタマーセンター",
  공식블로그: "公式ブログ",
  "(주)팡스카이 대표이사 지헌민, 대표이사 조호현 사업자 등록번호: 119-86-62250 통신판매업 신고번호: 제 2014-서울강남-01064":
    "株式会社PANGSKY 販売責任者イ・ビョンジン, 販売責任者 チョ・ホヒョン 事業者登録番号 : 119-86-62250 通信販売業申告番号 : 第2022-ソウルゴル-2036",
  "주소: 서울시 강남구 논현로 80길 25 (역삼동, 정우빌딩) 팩스: 070-8233-9383":
    "所在地：ソウル特別市九老区デジタル路31ギル20 1201号室",
  "안녕하세요.": "いつもありがとうございます。",
  "게임관련 문의는 해당 게임 홈페이지에서 [1:1문의]를 통해 보내 주시기 바랍니다.":
    "ゲームに関するお問い合わせは、ゲームのホームページから[1:1お問い合わせ]にお願いいたします。",
  "기타 문의는 panggamehelp@panggame.com 으로 보내 주시기 바랍니다.":
    "その他のお問い合わせは、panggamehelp@panggame.comにお願いいたします。",
  "감사합니다.": "よろしくお願いいたします。",
  로그인: "ログイン",
  회원가입: "新規会員の登録",
};
