export const m = {
  신규게임: "最新遊戲",
  "진행중인 이벤트": "活動",
  "이달의 쿠폰": "當月禮品序號",
  쿠폰: "禮品序號",
  복사: "複製",
  전체게임: "所有遊戲",
  모바일게임: "手機遊戲",
  PC게임: "PC遊戲",
  회사소개: "公司介紹",
  이용약관: "使用條款",
  "개인정보 처리방침": "個人資訊條約",
  고객센터: "客服中心",
  공식블로그: "粉絲團",
  "(주)팡스카이 대표이사 지헌민, 대표이사 조호현 사업자 등록번호: 119-86-62250 통신판매업 신고번호: 제 2014-서울강남-01064":
    "Pangsky Co., Ltd. 法人代表 李秉進，法人代表 趙浩鉉 營業執照號碼：119-86-62250 網絡許可證：第2014-首爾江南-01064",
  "주소: 서울시 강남구 논현로 80길 25 (역삼동, 정우빌딩) 팩스: 070-8233-9383":
    "公司地址：Room 1201, 20, Digital-ro 31-gil, Guro-gu, Seoul, Korea",
  "안녕하세요.": "您好。",
  "게임관련 문의는 해당 게임 홈페이지에서 [1:1문의]를 통해 보내 주시기 바랍니다.":
    "遊戲相關諮詢，請於遊戲官網【1:1】客服中心詢問。",
  "기타 문의는 panggamehelp@panggame.com 으로 보내 주시기 바랍니다.":
    "其他問題請發送至  pangtwmhcs@panggame.com 。",
  "감사합니다.": "感謝您的來訪。",
  로그인: "登入",
  회원가입: "會員註冊",
};
