<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
    
export default {
  components:{
  },
  name: 'App',
  mounted() {
    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.$store.commit('newScreenWidth',document.body.clientWidth);
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      if (!this.timer) {
        this.screenWidth = val;
        if (this.screenWidth < 768) {
          this.isCollapse = true;
        }
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 400);
      }
    },
  },
}
</script>

<style lang="scss">
//@import 'src/assets/css/style.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500&display=swap');
body {
  background-color:  #F8F9FA;
  font-size: 14px;
  // font-family: Dotum;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  margin: 0 auto;
}
</style>
