import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
import VueClipboard from 'vue-clipboard2'
import VueI18n from 'vue-i18n'

import store from './store'
import router from './router'

Vue.use(ElementUI) //使用elementUI
Vue.use(Vuex)
Vue.use(VueI18n)

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.config.productionTip = false

const locale = process.env.VUE_APP_LOCALE

const i18n = new VueI18n({
  locale:  locale?locale:'ko', 
  messages: {
    'ko': require('./lang/ko').m,   // 韩语
    'jp': require('./lang/jp').m,   // 日语
    'tw': require('./lang/tw').m,   // 繁体中文
  },
  silentTranslationWarn: true,
})

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
