
const app = {
    state: {
      screenWidth: document.body.clientWidth,
    },
    mutations: {
      newScreenWidth(state, val) {
        state.screenWidth = val;
      }
    },
    actions: {
    }
  }
  
  export default app
  