<template>
  <div class="app-wrapper">
    <div class="main-container">
      <navbar/>
      <app-main/>
      <app-footer/>
    </div>
  </div>
</template>

<script>
import {
  Navbar,
  AppMain,
  AppFooter
} from './components'

export default {
  name: 'Layout',
  components: {
    Navbar,
    AppMain,
    AppFooter
  },
  data () {
    return {
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
</style>
