import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../views/layout/Layout'


Vue.use(Router)

export const constantRouterMap = [
    { path: '/', redirect: '/home' },
    {
        path: '/home',
        component: Layout,
        children: [
          {
            path: '/home',
            component: () => import('../views/home/Home'),
            meta: {
              title: 'Home'
            }
          }
        ]
    },
]

const router = new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
      window.document.title = `Pangsky - ${to.meta.title}`
    } else {
      window.document.title = 'Pangsky'
    }
    next()
})

export default router