export const m = {
  신규게임: "신규게임",
  "진행중인 이벤트": "진행중인 이벤트",
  "이달의 쿠폰": "이달의 쿠폰",
  쿠폰: "쿠폰",
  복사: "복사",
  전체게임: "전체게임",
  모바일게임: "모바일게임",
  PC게임: "PC게임",
  회사소개: "회사소개",
  이용약관: "이용약관",
  "개인정보 처리방침": "개인정보 처리방침",
  고객센터: "고객센터",
  공식블로그: "공식블로그",
  "(주)팡스카이 대표이사 지헌민, 대표이사 조호현 사업자 등록번호: 119-86-62250 통신판매업 신고번호: 제 2014-서울강남-01064":
    "(주)팡스카이 대표이사 이병진, 대표이사 조호현 사업자 등록번호: 119-86-62250 통신판매업 신고번호: 제 2022-서울구로-2036",
  "주소: 서울시 강남구 논현로 80길 25 (역삼동, 정우빌딩) 팩스: 070-8233-9383":
    "서울시 구로구 디지털로31길 20, 1201호(구로동, 에이스테크노타워 5차) 팩스: 070-8233-9383",
  "안녕하세요.": "안녕하세요.",
  "게임관련 문의는 해당 게임 홈페이지에서 [1:1문의]를 통해 보내 주시기 바랍니다.":
    "게임관련 문의는 해당 게임 홈페이지에서 [1:1문의]를 통해 보내 주시기 바랍니다.",
  "기타 문의는 panggamehelp@panggame.com 으로 보내 주시기 바랍니다.":
    "기타 문의는 panggamehelp@panggame.com 으로 보내 주시기 바랍니다.",
  "감사합니다.": "감사합니다.",
  로그인: "로그인",
  회원가입: "회원가입",
};
