<template>
  <div class="footer">
    <div class="footer-container">
      <div class="links-container">
        <div>
          <a href="http://corp.panggame.com/kor/main.asp" target="_blank">{{
            $t("회사소개")
          }}</a>
        </div>
        <div>
          <a :href="terms_url" target="_blank">{{ $t("이용약관") }}</a>
        </div>
        <div>
          <a
            :href="privacy_url"
            target="_blank"
            style="color:#1890ff !important;text-decoration:underline;"
            >{{ $t("개인정보 처리방침") }}</a
          >
        </div>
        <div>
          <a style="cursor: pointer;" @click="showDialog">{{
            $t("고객센터")
          }}</a>
        </div>
        <div>
          <a href="http://corp.panggame.com/en/main.asp" target="_blank"
            >About PANGSKY</a
          >
        </div>
        <div>
          <a href="https://blog.naver.com/pangskycorp" target="_blank">{{
            $t("공식블로그")
          }}</a>
        </div>
      </div>
      <div class="copyright-container">
        <p>
          {{
            $t(
              "(주)팡스카이 대표이사 지헌민, 대표이사 조호현 사업자 등록번호: 119-86-62250 통신판매업 신고번호: 제 2014-서울강남-01064"
            )
          }}
        </p>
        <p>
          {{
            $t(
              "주소: 서울시 강남구 논현로 80길 25 (역삼동, 정우빌딩) 팩스: 070-8233-9383"
            )
          }}
        </p>
        <p>Copyright © PANGSKY Corp. All rights reserved.</p>
        <!-- <p>成都云海游科技有限公司</p> -->
      </div>
      <div class="logo-container">
        <img :src="logo" />
      </div>
    </div>
    <div v-if="dialogShowed" class="dialog">
      <div>
        <div class="close"><button @click="hideDialog"></button></div>
        <div class="content">
          <p>{{ $t("안녕하세요.") }}</p>
          <p>
            {{
              $t(
                "게임관련 문의는 해당 게임 홈페이지에서 [1:1문의]를 통해 보내 주시기 바랍니다."
              )
            }}
          </p>
          <p>
            {{
              $t(
                "기타 문의는 panggamehelp@panggame.com 으로 보내 주시기 바랍니다."
              )
            }}
          </p>
          <p>{{ $t("감사합니다.") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      logo: require("../../../assets/images/logo_footer.png"),
      dialogShowed: false,
    };
  },
  computed: {
    privacy_url: function() {
      if (this.$i18n.locale == "tw") {
        return "https://accounttw.panggame.com/#/policy?tab=1";
      }
      if (this.$i18n.locale == "jp") {
        return "https://corp.panggame.com/#/privacy";
      }
      return "https://accountkr.panggame.com/#/policy?tab=1";
    },
    terms_url: function() {
      if (this.$i18n.locale == "tw") {
        return "https://accounttw.panggame.com/#/policy?tab=0";
      }
      return "https://accountkr.panggame.com/#/policy?tab=0";
    },
  },
  methods: {
    showDialog() {
      this.dialogShowed = true;
    },
    hideDialog() {
      this.dialogShowed = false;
    },
  },
};
</script>

<style rel="stylesheet/scss" scoped lang="scss">
.logo-container img {
  filter: invert(100%);
}

.footer {
  height: 400px;
  margin-top: 100px;
  background-color: #222426;
}

.footer-container {
  padding-left: 12px;
  max-width: 1269px;
  margin: auto;
  padding-top: 40px;
  color: #f1f1f1;
}
.links-container {
  max-width: 960px;
  border-bottom: 1px solid #5a5a5a;
}
.links-container > div {
  display: inline-block;
  margin: 15px 0;
  padding: 0 14px;
  line-height: 13px;
  font-size: 13px;
  border-right: 1px solid #5a5a5a;
}

.links-container > div:nth-child(1) {
  padding-left: 0;
}

.links-container > div:last-child {
  border-right: none;
}
.links-container a {
  color: #ffffff !important;

  text-decoration: none;
}
.copyright-container {
  color: #b4b4b4;
  font-size: 12px;
  margin-top: 15px;
}
.copyright-container p {
  margin: 5px 0;
}
.logo-container {
  margin-top: 30px;
}
.logo-container img {
  width: 126px;
  height: 17px;
  opacity: 0.67;
}
.is-active {
  color: #218cff;
}
.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 1000000;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
}
.dialog > div {
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 740px;
  padding: 18px 40px 20px 40px;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.dialog .content {
  padding: 20px;
  text-align: center;
}

.close {
  float: right;
}

.close button {
  position: absolute;
  right: 20px;
  top: 18px;
  display: block;
  width: 33px;
  height: 33px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.close button::before {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  border-right: 1px solid #000;
  top: 0;
  left: 16px;
  transform: rotate(45deg);
}

.close button::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  border-right: 1px solid #000;
  top: 0;
  left: 16px;
  transform: rotate(-45deg);
}
</style>
